<template>
  <section
    class="course_status"
    :class="{
      'scale-mobile': isMobile,
    }"
    v-if="show"
    :style="stylesheet"
    :is-example="isExample"
  >
    <section class="course_status__content" :has_access="hasAccess">
      <span>{{ hasAccess ? $t("home2.course_template.available") : $t("home2.course_template.not_available") }}</span>
    </section>
  </section>
</template>

<script>
// Services
import { mapActions } from "vuex";

export default {
  name: "IsAvailable",
  props: {
    "has-access": {
      type: Boolean,
      required: true,
      default: () => false,
    },
    "is-example": {
      type: Boolean,
      required: false,
      default: () => false,
    },
    "example-available-color": {
      type: String,
      required: false,
      default: () => null,
    },
    "example-not-available-color": {
      type: String,
      required: false,
      default: () => null,
    },
    isMobile: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    available_color() {
      return this.exampleAvailableColor || this.$store.state.metas.metas.available_color || "#2133d2"
    },
    not_available_color() {
      return this.exampleNotAvailableColor || this.$store.state.metas.metas.not_available_color || "#ffcb37"
    },
    show() {
      return this.$store.state?.metas?.metas?.is_available_course === "on" || false;
    },
    stylesheet() {
      return {
        "--available-color": this.available_color,
        "--not-available-color": this.not_available_color
      };
    },
  },
  methods: {
    ...mapActions(["fetchMetas"]),
    async getMetas() {
      await this.fetchMetas([
        "is_available_course",
        "available_color",
        "not_available_color",
      ]);
    },
  },
  mounted() {
    this.getMetas();
  },
};
</script>

<style lang="scss" scoped>
.scale-mobile {
  scale: 0.9 !important;
  padding-left: 30px;
}
.course_status {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute !important;
  bottom: 20px;
  right: 25px;
  z-index: 9;

  &__content {
    min-width: 80px;
    height: 25px;
    display: flex;
    align-items: center;
    background: var(--not-available-color);
    padding: 10px;
    border-radius: 5px;
    color: black;

    font-family: Montserrat;
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;

    &[has_access] {
      background: var(--available-color);
      color: white;
    }
  }

  &[is-example] {
    position: relative !important;
    justify-content: flex-start;
    align-items: center;
    bottom: 0px;
    right: 0px;

    &__content {
      text-align: center;
    }
  }
}
</style>
