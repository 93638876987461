<template>
  <div v-if="(category.courses && category.courses.length) || (category.modules && category.modules.length)" class="category-container" :class="`category-${category.id}`">
    <span v-if="showProgress" class="category-title"> Continue Assistindo </span>
    <span v-else class="category-title">{{ category.title }}</span>
    <HorizontalScroll v-if="!withoutCategory && !only_my_courses && !reorder" :isMobile="isMobile" :categoryId="`${category.id}`">
      <CardCourse
        v-for="(course, i) in orderedCourses"
        :key="`item-course-${i}`"
        :course="course"
        :ratio="ratio"
        :permission="permission"
        :homeVertical="homeVertical"
        :availible="availible"
        :isModule="false"
        :ratingHome="ratingHome"
        :isMobile="isMobile"
        :showldShow="true"
        :showProgress="showProgress"
      />
    </HorizontalScroll>
    <draggable
      v-if="!withoutCategory && !only_my_courses && reorder"
      :v-model="category.courses"
      class="reorder-container"
      @end="onEnd"
    >
      <div
        v-for="(course, i) in orderedCourses"
        :data-id=" course.course_id || course.id"
        :key="`item-course-${i}`"
        class="item-drag pb-2"
      >
        <CardCourse
          :course="course"
          :ratio="ratio"
          :permission="permission"
          :homeVertical="homeVertical"
          :availible="availible"
          :isModule="false"
          :ratingHome="ratingHome"
          :showldShow="true"
          :showProgress="showProgress"
          :isMobile="isMobile"
        />
      </div>
    </draggable>
    <HorizontalScroll v-else-if="!withoutCategory && only_my_courses" :isMobile="isMobile" :categoryId="`${category.id}`">
      <CardCourse
        v-for="(course, i) in category.courses"
        :key="`item-course-${i}`"
        :course="course"
        :ratio="ratio"
        :permission="permission"
        :homeVertical="homeVertical"
        :availible="availible"
        :isModule="false"
        :ratingHome="ratingHome"
        :showldShow="true"
        :isMobile="isMobile"
        :showProgress="showProgress"
      />
    </HorizontalScroll>
    <HorizontalScroll v-else-if="withoutCategory && !only_my_courses" :isMobile="isMobile" :categoryId="`${category.id}`">
      <CardCourse
        v-for="(course, i) in category.modules"
        :key="`item-course-${i}`"
        :course="course"
        :ratio="ratio"
        :permission="permission"
        :homeVertical="homeVertical"
        :availible="availible"
        :isModule="true"
        :ratingHome="ratingHome"
        :showldShow="course.should_show"
        :showProgress="showProgress"
        :isMobile="isMobile"
      />
    </HorizontalScroll>
    <HorizontalScroll v-else-if="withoutCategory && only_my_courses" :isMobile="isMobile" :categoryId="`${category.id}`">
      <CardCourse
        v-for="(course, i) in category.modules"
        :key="`item-course-${i}`"
        :course="course"
        :ratio="ratio"
        :permission="permission"
        :homeVertical="homeVertical"
        :availible="availible"
        :isModule="true"
        :ratingHome="ratingHome"
        :showldShow="course.should_show"
        :showProgress="showProgress"
        :isMobile="isMobile"
      />
    </HorizontalScroll>
  </div> 
</template>

<script>
import CardCourse from '../cardCourse/index.vue';
import HorizontalScroll from '../horizontalScroll/index.vue';
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();
import draggable from "vuedraggable";
import notify from "@/services/libs/notificacao";
import { EventBus } from '@/main.js';

export default {
  props: {
    category: {
      type: Object,
      default: () => {},
    },
    ratio: {
      type: Object,
      required: true
    },
    permission: {
      type: Boolean,
      default: false,
    },
    onlyMyCourses: {
      type: Boolean,
    },
    homeVertical: {
      type: Boolean,
      default: false,
    },
    withoutCategory: {
      type: Boolean,
      default: false
    },
    availible: {
      type: Object,
    },
    ratingHome: {
      type: Boolean,
      default: false
    },
    showProgress: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
  },
  components: {
    CardCourse,
    HorizontalScroll,
    draggable
  },
  computed: {
    orderedCourses: {
      get() {
        const courses_for_filter = this.category.courses || [];
        const courses = courses_for_filter.sort((a, b) => a.order - b.order);
        return courses;
      },
      set(value) {
        // console.log("value", value);
        // this.category.courses = value;
      },
    }
  },
  data() {
    return {
      only_my_courses: '',
      without_category: '',
      reorder: false,
    }
  },
  created() {
    this.checkMyCourses();
    this.checkWithoutCategory();
    EventBus.$on("reorder-course", this.initReorder);
  },
  methods: {
    checkMyCourses() {
      if (this.$route.query.filter == 'meus-cursos') {
        this.only_my_courses = true;
      } else {
        this.only_my_courses = false;
      }
    },
    checkWithoutCategory() {
      if (this.withoutCategory) {
        this.without_category = true;
      } else {
        this.without_category = false;
      }
    },
    initReorder() {
      this.reorder = !this.reorder;
    },
    cancelReorder() {
      this.reorder = false;
    },
    onEnd: function (e) {
      console.log("event", e);
      setTimeout(() => {
        var array = [];
        var lessons = document.querySelectorAll(".item-drag");
        // console.log("evlll", lessons);

        for (let i = 0; i < lessons.length; i++) {
          const lessonId = lessons[i].getAttribute("data-id");
          array.push(parseInt(lessonId));
        }
        let data = {
          id: "/order",
          array,
        };
        serviceCourse
          .postIDArray(data)
          .then((resp) => {
            notify("sucesso", "Ordenação Salva!");
          })
          .catch((err) => {
            notify("erro", "Erro ao Salvar Ordenação!");
          });
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.category-container {
  left: 0;
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 17px;
}

.category-container:last-child {
  margin-bottom: 130px;
}

.category-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
  margin-top: 32px;
  color: var(--fontcolor);
}
@media screen and (min-width: 768px) {
  .category-container {
    padding-left: 63px;
    left: 0;
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
}
.reorder-container {
  display: flex; 
  width: 100%;
  flex-wrap: wrap;
}
</style>
