<template>
  <div
    class="horizontal-scroll"
    @mousedown="startDrag"
    @touchstart="startDrag"
    @mousemove="drag"
    @touchmove="drag"
    @mouseup="endDrag"
    @touchend="endDrag"
  >
    <button v-show="righClicked > 0 || showInMobileOnScroll" @click="scrollLeft" class="scroll-button left-shaddow" :class="`scroll-button-${categoryId}`" ref="leftButton">
      <div style="top: 0px !important;" class="arrow left-arrow"><i class="fa fa-angle-left"></i></div>
    </button>
    <div
      class="content-scroll"
      ref="content"
      @touchmove="handleScroll"
      :has-carousel="hasCarousel"
    >
      <slot></slot>
    </div>
    <button style="right: 0px !important;"  v-show="showButtonRight() || showInMobileOnScroll" @click="scrollRight " class="scroll-button right-shaddow" :class="`scroll-button-${categoryId}`" ref="rightButton">
      <div style="top: 0px !important;" class="arrow right-arrow"><i class="fa fa-angle-right"></i></div>
    </button>
  </div>
</template>

<script>
// Services
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
import { EventBus } from "@/main.js"

export default {
  name: "HorizontalScroll",
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    categoryId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      righClicked: 0,
      checking: false,
      isDragging: false,
      startDragX: 0,
      startScrollLeft: 0,
      hasCarousel: false,
      showInMobileOnScroll: false,
      timeRemaining: 5, 
      timer: null,
    };
  },
  mounted() {
    this.getCarousel();
    EventBus.$on("template-scroll", this.disableShowButtonInMobileScroll);
  },
  beforeUnmount() {
    this.$refs.content.removeEventListener("scroll", this.checkShowButtons);
  },
  methods: {
    disableShowButtonInMobileScroll() {
      const elements = document.getElementsByClassName('scroll-button');
      if (this.isMobile) {
        this.showInMobileOnScroll = false;
        elements.forEach(element => {
          element.style.visibility = 'hidden';
        });
      }
    },
    showButtonRight() {
      if (!this.$refs.content) return false;
      const contentWidth = this.$refs.content.scrollWidth;
      const containerWidth = this.$refs.content.clientWidth;
      return contentWidth > containerWidth;
    },
    scrollLeft() {
      this.righClicked--;
      this.$refs.content.scrollBy({ left: -540, behavior: "smooth" });
      if (this.righClicked === 0) {
        this.disableShowButtonInMobileScroll();
      }
    },
    scrollRight() {
      this.righClicked++;
      this.$refs.content.scrollBy({ left: 540, behavior: "smooth" });
    },
    handleScroll() {
      if (this.isMobile && this.showButtonRight()) {
        const elements = document.getElementsByClassName(`scroll-button-${this.categoryId}`);
        elements[0].style.visibility = 'visible';
        elements[1].style.visibility = 'visible';
        this.showInMobileOnScroll = true;
      }
      if (!this.checking) {
        this.scrolled();
      }
    },
    scrolled() {
      this.righClicked++;
    },
    checkScroller(type) {
      if (this.righClicked > 0) {
        switch (type) {
          case "+":
            this.righClicked++;
            this.righClicked++;
            break;
            case "-":
            this.righClicked--;
            this.righClicked--;
            break;
          default:
            return;
        }
      }

      if (type === "+" && this.righClicked === 0) {
        this.$refs.content.scrollBy({ left: -5000, behavior: "easy" });
      }
      if (type === "-" && this.righClicked === 0) {
        this.$refs.content.scrollBy({ left: 5000, behavior: "easy" });
      }
      this.checking = false;
    },
    startDrag(event) {
      this.handleScroll();
      this.isDragging = true;
      this.startDragX = event.clientX || event.touches[0].clientX;
      this.startScrollLeft = this.$refs.content.scrollLeft;
    },
    drag(event) {
      if (this.isDragging) {
        const scrollAmount =
          this.startScrollLeft +
          (this.startDragX - (event.clientX || event.touches[0].clientX));
        this.$refs.content.scrollLeft = scrollAmount;
      }
    },
    endDrag() {
      this.isDragging = false;
    },
    async getCarousel() {
      await serviceMeta.search("keys[]=home_carousel").then((resp) => {
        this.hasCarousel = resp.home_carousel === "on";
      });
    },
  },
};
</script>

<style lang="scss">
.horizontal-scroll {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: nowrap;
}

.scroll-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: #333;
  font-size: 1rem;
  z-index: 999;
  visibility: hidden;
  // margin-top: 70px;
  height: 100%;
  display: flex;
}
@media screen and (min-width: 768px) {
  .scroll-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    color: #333;
    font-size: 1rem;
    z-index: 999;
    visibility: hidden;
    // margin-top: 140px;
    height: 100%;
    display: flex;
  }
}

.horizontal-scroll:hover {
  .scroll-button {
    visibility: visible;
  }
}

.content-scroll {
  display: flex;
  flex-wrap: wrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  touch-action: pan-y;
  min-width: 100%;
  overflow: hidden;
  right: 0;
  z-index: 30;
  gap: 5px;
}

.content-scroll[has-carousel] {
  flex-wrap: nowrap;
}

.left {
  left: 0;
  height: 50px;
  width: 25px;
  border-radius: 50%;
  box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
}

.right {
  right: 30px !important;
  top: 50%;
  position: relative;
}

.right-shaddow {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: linear-gradient(to right, rgba(0,0,0,0.3), rgba(0,0,0,0.3));
}
.left-shaddow {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(to left, rgba(0,0,0,0.3), rgba(0,0,0,0.3));
}
.scroll-button .arrow:hover {
  scale: 1.3;
}
</style>
