<template>
  <div
    v-if="!isLoadingFeatured"
    class="highlight-container"
    data-anima="top"
  >
    <div
      v-if="!isLoadingFeatured"
      data-anima="top"
      :style="this.background"
      class="highlight-inner-container"
    >
      <modal-termos-v2 v-if="isStudent" :siteid="currentSiteId"></modal-termos-v2>
      <div style="max-width: 100%; overflow: hidden;" :class="`highlight-transparency highlight-transparency-${theme}`">
        <Navbar :isMobile="isMobile" />
        <div
          data-anima="top"
          style="display: flex; align-items: center; width: 100%; height: 100%; max-height: 550px;"
        >
          <div style="width: 100%; height: 304px">
            <span v-if="!isMobile" :class="`default-font-${defaultFont}`" data-anima="top" class="highlight-title">
              {{ featured_course.data.title }}
            </span>
            <span :class="`default-font-${defaultFont}`" data-anima="top" v-else class="highlight-title">
              {{ featured_course.data.title ? featured_course.data.title.substring(0, 32) + '...' : '' }}
            </span>
            <span data-anima="top" v-if="!isMobile" :class="`highlight-subtitle highlight-subtitle`">
              {{ featured_course.data.description }}
            </span>
            <span data-anima="top" v-else :class="`highlight-subtitle highlight-subtitle`">
              {{ featured_course.data.description ? featured_course.data.description.substring(0, 33) + '...' : '' }}
            </span>
            <div v-if="featured_course.id !== '0'" data-anima="top" class="higilight-button-container">
              <button
                @click="$router.push(`/curso/${featured_course.data.id}`)"
                class="highlight-button-action"
                v-if="featured_course.data.has_access"
              >
                <img class="play-icon" src="play.svg" alt="play-icon" />
                {{ $t("home2.access_content") }}
              </button>
              <button @click="clicked" class="highlight-button-action" v-else>
                <img class="play-icon" src="play.svg" alt="play-icon" />
                {{ $t("home2.buy_now") }}
              </button>
              <button
                v-if="!isMobile"
                :class="`highlight-button-info highlight-button-info`"
                v-b-modal.see-more
              >
              {{ $t("home2.more_informations") }}
              </button>
            </div>
          </div>
          <button @click="openModalUpload"  v-if="!isMobile && isOwnerOrAdmin && !likeStudent"  class="mr-2 button-new-course button-midia button-aligned">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.22083 16.8986H15.3897C16.3497 16.8986 17.1281 16.1203 17.1281 15.1602V2.99133C17.1281 2.03124 16.3497 1.25293 15.3897 1.25293H3.22083C2.26073 1.25293 1.48242 2.03124 1.48242 2.99133V15.1602C1.48242 16.1203 2.26073 16.8986 3.22083 16.8986ZM3.22083 16.8986L12.782 7.33734L17.1281 11.6834M7.56684 6.03354C7.56684 6.75361 6.9831 7.33734 6.26303 7.33734C5.54296 7.33734 4.95923 6.75361 4.95923 6.03354C4.95923 5.31347 5.54296 4.72974 6.26303 4.72974C6.9831 4.72974 7.56684 5.31347 7.56684 6.03354Z" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ $t('home2.media_library') }}
          </button>
          <button @click="initReorder" v-if="!isMobile && isOwnerOrAdmin && !likeStudent && showReorder && !reorder" id="stepReorder" class="mr-2 button-new-course">
            {{ $t('home2.reorder_course') }}
          </button>
          <button @click="initReorder" v-if="!isMobile && isOwnerOrAdmin && !likeStudent && showReorder && reorder" id="stepReorder" class="mr-2 button-new-course">
            {{ $t('home2.reorder_course_exit') }}
          </button>
          <button @click="$router.push('/config_curso')" v-if="!isMobile && isOwnerOrAdmin && !likeStudent" id="step3" class="button-new-course">
            {{ $t('home2.new_course') }}
          </button>
        </div>

        <b-modal ref="see-more" id="see-more" hide-footer hide-header>
          <span :class="`title-modal`">{{ featured_course.data.title }}</span>
          <p class="my-4 desc-modal">{{ featured_course.data.description }}</p>
          <b-button class="close-modal" @click="hideModal()">
            {{ $t('home2.close') }}
          </b-button>
        </b-modal>
      </div>
    </div>
    <ModalUpload/>
  </div>
</template>

<script>
import notify from '@/services/libs/notificacao';
import Navbar from '@/components/V2/navbar/index.vue';
import MemberService from "@/services/resources/MemberService";
import ModalTermosV2 from "@/components/V2/modals/ModalTermos.vue";
import MetaService from "@/services/resources/MetaService";
import ModalUpload from '@/components/ModalUpload.vue';
import Cookies from 'js-cookie';
import { EventBus } from '@/main.js';

const serviceMeta = MetaService.build();
const serviceMember = MemberService.build();

export default {
  props: ['isMobile', 'featured', 'isLoading', 'customCover', 'showReorder'],
  components: {
    Navbar,
    ModalTermosV2,
    ModalUpload
  },
  data() {
    return {
      background_style: this.background,
      isOwnerOrAdmin: false,
      currentSiteId: null,
      likeStudent: false,
      reorder: false,
    };
  },
  created() {
    this.checkOwnerOrAdmin();
    this.viewLikeStudent();
  },
  computed: {
    currentSite() { 
      return this.$store.getters.currentSite;
    },
    background() {

      let background = null;

      if(this.isMobile){
        background = this.featured_course?.cover?.cdn_url_mobile || this.featured_course.cover.cdn_url || 'default-background.png';
        return ("background-image: linear-gradient(90.17deg, #16181A 10.88%, rgba(22, 23, 26, 0) 127.03%), url('" + background + "'); background-size: cover; background-position: top right;");
      } else {
        background = this.featured_course?.cover?.cdn_url || 'default-background.png';
        return ("background-image: linear-gradient(90.17deg, #16181A 10.88%, rgba(22, 23, 26, 0) 127.03%), url('" + background + "'); background-size: cover; background-repeat: no-repeat; background-position: top right;");
      }
    },
    isLoadingFeatured() {
      return this.isLoading;
    },
    custom_cover() {
      return this.customCover;
    },
    featured_course() {
      return this.featured;
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
    isStudent() {
      if (!this.$store.getters.currentSite.currentMember) return false;
      if (this.$store.getters.currentSite.currentMember.role) {
        return this.$store.getters.currentSite.currentMember.role === 'student';
      } else {
        return false;
      }
    },
    defaultFont() {
      return this.$store.state.metas.metas.default_font;
    }
  },
  mounted() {
    this.getMetaTermos();
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId === "modal-termos-v2") {
        this.verifyAcceptTerms();
      }
    });
  },
  methods: {
    openModalUpload() {
      this.$root.$emit('uploadlesson', 'VIDEO');
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnShow');
    },
    initReorder() {
      if(this.reorder) {
        localStorage.removeItem('home');
        location.reload();
      } else {
        this.reorder = !this.reorder;
        EventBus.$emit('reorder-course');
      }
    },
    viewLikeStudent() {
      let permission = Cookies.get("access_level");
      let typeView = Cookies.get("type_student");
      if ((permission === "admin" || permission === "owner" || permission === "student_moderator") && (typeView === 1 || typeView === "1")) {
        this.likeStudent = true;
      }
    },
    clicked() {
      if (!this.featured_course.data.has_access) {
        if (this.featured_course.data?.course_id) {
          this.openModalBuy(this.featured_course.data, true);
        } else {
          this.openModalBuy(this.featured_course.data);
        }
        return;
      }
    },
    openModalBuy(data, module) {
      if (!this.featured_course.data.is_liberated) {
        notify('erro', this.$t('home2.course_template.error.liberation'));
      } else {
        notify('erro', this.$t('home2.course_template.error.access'));
        if (module) {
          this.$root.$emit('buy-course-modal-v2', data.curso);
        } else {
          this.$root.$emit('buy-course-modal-v2', data);
        }
      }
    },
    hideModal() {
      this.$refs['see-more'].hide();
    },
    checkOwnerOrAdmin() {
      const currentSite = this.$store.getters.currentSite || {
        currentMember: {},
      };
      if(["admin", "owner"].includes(currentSite.currentMember.role)) {
        this.isOwnerOrAdmin = true;
      }
    },
    getMetaTermos() {
      serviceMeta
        .search("keys[]=termsOfUseActivated")
        .then((resp) => {
          var data = resp.termsOfUseActivated;
          if (data === "on") {
            this.verifyAcceptTerms();
          }
        });
    },
    async verifyAcceptTerms() {
      this.currentSiteId = this.currentSite.currentSite.id;
      await serviceMember
        .read("/meta?keys[]=_termos_" + this.currentSiteId)
        .then((resp) => {
          const property = ("_termos_" + this.currentSiteId).toString();
          if (resp[property] !== "accepted") {
            this.$root.$emit("bv::show::modal", "modal-termos-v2", "#btnShow");
          }
        });
    },
  },
};
</script>

<style lang="scss">

.button-midia {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: fit-content;
  min-width: unset !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  svg path {
    stroke: var(--text-color);
  }
}
.title-modal {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  display: block;
  color: var(--fontcolor-v2);
}
.desc-modal {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--fontcolor2-v2);
  text-align: center;
  white-space: pre-wrap;
}
.close-modal {
  box-sizing: border-box;
  height: 50px;
  border: none;
  white-space: nowrap;
  background: var(--maincolor);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #16181a;
  border-radius: 50px;
  margin: 0 auto;
  padding-right: 28px;
  padding-left: 28px;
}

.highlight-container {
  display: flex;
  width: 100%;
  position: absolute;
  left: 0;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: 400px;
  max-height: 400px;
  overflow: hidden;
  background-size: 100% 100%;
}

.highlight-subtitle {
  max-width: 600px;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.highlight-transparency {
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  height: 400px;
  background-size: 100 80;
}
.highlight-transparency-dark {
  background-image: linear-gradient(
    90.17deg,
    #16181a 10.88%,
    rgba(22, 23, 26, 0) 127.03%
  );
  background-size: 100 80;
}

.highlight-transparency-light {
  background-image: linear-gradient(
    90.17deg,
    #e4e4e4 10.88%,
    rgba(255, 255, 255, 0) 127.03%
  );
  background-size: 100 80;
}

.highlight-upper-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--fontcolor2-v2);
}

.highlight-title {
  font-family: 'Montserrat Alternates';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 36px;
  display: flex;
  white-space: wrap;
  align-items: center;
  margin-top: 15px;
  color: var(--text-color);
}
@media screen and (min-width: 968px) {
  .button-new-course {
    margin-top: 230px;
    min-width: 200px;
    position: relative;
    cursor: pointer;
    height: 50px;
    border: 1px solid var(--text-color);
    border-radius: 50px;
    background-color: transparent;
    color: var(--text-color);
    padding: 8px 24px;
    font-size: 15px;
    font-weight: 600;
    z-index: 11;
  }
}

@media screen and (min-width: 768px) {
  .highlight-title {
    font-size: 3rem;
    margin-bottom: 25px;
    color: var(--text-color);
  }
  .highlight-subtitle {
    font-size: 0.7rem;
    font-size: 18px;
  }
  .highlight-transparency {
    padding-left: 56px;
    padding-right: 56px;
    height: 550px;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .highlight-container {
    max-height: 550px;
    min-height: 550px;
    overflow: hidden !important;
  }
}

.highlight-subtitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-color);
}

.higilight-button-container {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 32px;
}
.highlight-button-action {
  box-sizing: border-box;
  height: 50px;
  border: none;
  white-space: nowrap;
  background: var(--maincolor);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #16181a;
  border-radius: 50px;
  margin-right: 16px;
  padding-right: 38px;
  padding-left: 28px;
}
.highlight-button-info {
  box-sizing: border-box;
  height: 50px;
  font-weight: 600;
  border-radius: 50px;
  white-space: nowrap;
  padding-right: 28px;
  padding-left: 28px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--text-color);
  color: var(--text-color);
}

.play-icon {
  margin-right: 12px;
}
.button-new-course {
  min-width: 200px;
  cursor: pointer;
  height: 50px;
  border: 1px solid var(--text-color);
  border-radius: 50px;
  background-color: transparent;
  color: var(--text-color);
  padding: 8px 24px;
  font-size: 15px;
  font-weight: 600;
  z-index: 40;
}
.highlight-inner-container {
  overflow: hidden !important; 
  min-width: 100%;
}
</style>
